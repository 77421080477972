import React, { useState, createContext, useEffect, useContext } from 'react'
import { LocalStorageKeys } from '~config/constants'
import { useUserContext } from '~config/user-context'

import {
    FilterOp,
    AuthRoles,
    useGetClinicQuery,
    ClinicFieldsFragment,
    useGetOrganizationQuery,
    OrganizationFieldsFragment,
    useQueryOrganizationsQuery,
    useGetClinicAdminsByClinicQuery,
    useGetClinicsForUserAdminQuery,
    useGetClinicAdminQuery,
    useCountOrganizationsQuery,
    useCountClinicsForUserAdminQuery,
} from '~graphql/generated/graphql'
import { csArray } from '~utils/helpers'

type ClinicProviderType = {
    clinic: ClinicFieldsFragment | undefined
    hasMultipleClinics: boolean
    setClinic: (e: ClinicFieldsFragment | undefined) => void
    organization: OrganizationFieldsFragment | undefined
    setOrganization: (e: OrganizationFieldsFragment | undefined) => void
    isClinicAdmin: boolean
    refetchClinic: ({ id }: { id: string }) => Promise<any>
    clinicInStorage: boolean | undefined
    loading: boolean
}

export const ClinicContext = createContext<ClinicProviderType>({
    clinic: undefined,
    setClinic: () => {},
    hasMultipleClinics: false,
    organization: undefined,
    setOrganization: () => {},
    isClinicAdmin: false,
    refetchClinic: () => Promise.resolve(),
    clinicInStorage: false,
    loading: true,
})

export const ClinicProvider = ({ children }: { children: React.ReactNode }) => (
    <ClinicContext.Provider value={{ ...useClinicValues() }}>
        {children}
    </ClinicContext.Provider>
)

function useClinicValues(): ClinicProviderType {
    const { userId, user } = useUserContext()

    const [clinic, setClinic] = useState<ClinicFieldsFragment>()
    const [organization, setOrganization] =
        useState<OrganizationFieldsFragment>()

    const [isClinicAdmin, setIsClinicAdmin] = useState<boolean>(false)
    const [hasMultipleClinics, setHasMultipleClinics] = useState<boolean>(false)

    const [clinicInStorage, setClinicInStorage] = useState<string>()
    const [organizationInStorage, setOrganizationInStorage] = useState<string>()

    const handleSetClinic = (clinic: ClinicFieldsFragment | undefined) => {
        const organization = localStorage.getItem(
            LocalStorageKeys.selectedOrganization,
        )

        if (clinic) {
            localStorage.setItem(LocalStorageKeys.selectedClinic, clinic.id)
            if (organization)
                localStorage.removeItem(LocalStorageKeys.selectedOrganization)
        } else {
            localStorage.removeItem(LocalStorageKeys.selectedClinic)
        }
        setClinic(clinic)
    }

    const handleSetOrganization = (
        organization: OrganizationFieldsFragment | undefined,
    ) => {
        const clinic = localStorage.getItem(LocalStorageKeys.selectedClinic)

        if (organization) {
            localStorage.setItem(
                LocalStorageKeys.selectedOrganization,
                organization.id,
            )
            if (clinic) localStorage.removeItem(LocalStorageKeys.selectedClinic)
        } else {
            localStorage.removeItem(LocalStorageKeys.selectedOrganization)
        }
        setOrganization(organization)
    }

    const {
        data: clinicData,
        refetch,
        loading: clinicLoading,
    } = useGetClinicQuery({
        variables: {
            id: clinicInStorage || '',
        },
        onError: () => {
            setClinic(undefined)
            setClinicInStorage(undefined)
        },
        skip: !clinicInStorage || !user,
    })

    const { data: clinicAdminData } = useGetClinicAdminQuery({
        variables: {
            clinicId: clinic?.id || '',
            userId: userId || '',
        },
        skip: !clinic?.id || !user,
    })

    const { data: organizationData, loading: organizationLoading } =
        useGetOrganizationQuery({
            variables: {
                id: organizationInStorage || '',
            },
            onError: () => {
                setOrganization(undefined)
                setOrganizationInStorage(undefined)
            },
            skip: !organizationInStorage || !user,
        })

    const { data: adminOrgsCount } = useCountOrganizationsQuery({
        variables: {
            where: [
                {
                    column: 'admins',
                    filterOp: FilterOp.Cs,
                    value: { string: csArray([user?.id || '']) },
                },
            ],
        },
    })

    const { data: adminClinicsCount } = useCountClinicsForUserAdminQuery({
        variables: {
            userId: userId || '',
        },
        skip: !userId,
    })

    /* Clinic state flow START */
    // Check in storage for clinic when loaded
    useEffect(() => {
        const clinicInStorage = localStorage.getItem(
            LocalStorageKeys.selectedClinic,
        )
        setClinicInStorage(clinicInStorage || undefined)
    }, [])

    // If clinic data received, set clinic state
    useEffect(() => {
        setClinic(clinicData?.clinic as ClinicFieldsFragment)
    }, [clinicData])
    /* Clinic state flow END */

    /* Organization state flow START */
    // Check in storage for organization when loaded
    useEffect(() => {
        const organizationInStorage = localStorage.getItem(
            LocalStorageKeys.selectedOrganization,
        )
        setOrganizationInStorage(organizationInStorage || undefined)
    }, [])

    // If organization data received, set organization state
    useEffect(() => {
        setOrganization(
            organizationData?.organization as OrganizationFieldsFragment,
        )
    }, [organizationData])
    /* Organization state flow END */

    // Check and set user role for selected clinic
    useEffect(() => {
        const role = clinicAdminData?.getClinicAdmin?.role
        setIsClinicAdmin(role ? role === AuthRoles.ClinicManager : false)
    }, [clinicAdminData])

    useEffect(() => {
        const userClinics = adminClinicsCount?.countClinicsForUserAdmin || 0
        const userOrgs = adminOrgsCount?.countOrganizations || 0
        setHasMultipleClinics(Boolean(userOrgs + userClinics > 1))
    }, [adminClinicsCount, adminOrgsCount])

    return {
        clinic,
        setClinic: handleSetClinic,
        hasMultipleClinics,
        organization,
        setOrganization: handleSetOrganization,
        isClinicAdmin,
        refetchClinic: refetch,
        clinicInStorage: Boolean(clinicInStorage),
        loading: clinicLoading || organizationLoading,
    }
}

export const useClinic = () => {
    return useContext(ClinicContext)
}
