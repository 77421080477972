import React from 'react'
import { Icon } from '@chakra-ui/icon'
import { FaList } from 'react-icons/fa'
import { Avatar } from '@chakra-ui/avatar'
import { addMinutes, format } from 'date-fns'
import { Box, Text, HStack, VStack } from '@chakra-ui/layout'

import {
    AppointmentFieldsFragment,
    AppointmentStatus,
    RescheduleFieldsFragment,
    useGetChatByClientAndClinicQuery,
} from '~graphql/generated/graphql'
import AppointmentStatusTag from '~components/shared/appointment-status-tag'
import { useUserContext } from '~config/user-context'
import GradientDivider from '~components/shared/gradient-divider'
import { Button } from '@chakra-ui/react'
import { FiExternalLink } from 'react-icons/fi'
import InfoItem, { InfoText } from '~components/shared/info-item'
import SupabaseLink from '~components/shared/supabase-link'
import { SupabaseEntities } from '~config/constants'
import { UserRoutes } from '~config/routes'
import SharedHelpText from '~components/shared/form/shared-help-text'
import { getAppointmentStatusFromAppointment } from '~utils/appointment-helpers'
import ClickToCopyText from '~components/shared/modals/appointment-details/click-to-copy'
import ClinicianAvatar from '~components/shared/clinician-avatar'

interface Props {
    appointment: AppointmentFieldsFragment
    reschedule?: RescheduleFieldsFragment
}

const AppointmentInfoSection: React.FC<Props> = ({
    appointment,
    reschedule,
}) => {
    const serviceName = appointment.service?.name
        ? appointment.service?.name + ' appointment'
        : appointment.service_name
    const { isAdmin } = useUserContext()

    const { data: chatData } = useGetChatByClientAndClinicQuery({
        variables: {
            clientId: appointment.client?.id || 'no_client',
            clinicId: appointment.clinic.id,
        },
        skip: !appointment.client?.id,
    })

    const chat = chatData?.getChatByClientAndClinic || null

    const status = getAppointmentStatusFromAppointment(appointment)

    return (
        <Box w="full" h="fit-content">
            <HStack spacing={4} px={2} py={1}>
                <Icon as={FaList} color="gray.900" />
                <Text fontWeight="semibold">Details</Text>
            </HStack>
            <GradientDivider my={2} />
            <VStack w="full" spacing={6} p={2}>
                <InfoItem
                    label="Status"
                    info={
                        <HStack alignItems="center">
                            <AppointmentStatusTag
                                status={status}
                                shouldDisableTooltip
                            />
                            <Text fontSize="sm" fontWeight="medium">
                                {status.capitalize()}
                            </Text>
                            {appointment.clinic_appt_status && (
                                <Text fontSize="xs" color="gray.500">
                                    {`(${appointment.clinic_appt_status.description})`}
                                </Text>
                            )}
                        </HStack>
                    }
                />
                {status === AppointmentStatus.Denied && (
                    <InfoItem
                        label="Denial Reason"
                        info={
                            <Text
                                fontSize="sm"
                                fontWeight="normal"
                                fontStyle="italic"
                            >
                                {appointment.reason_for_cancellation ||
                                    'No reason provided'}
                            </Text>
                        }
                    />
                )}
                <InfoItem
                    label={reschedule ? 'Original Time' : 'Time'}
                    info={
                        <Box>
                            <Text fontWeight="normal" fontSize="sm">{`${format(
                                appointment.start_time,
                                'p',
                            )} to ${format(
                                addMinutes(
                                    appointment.start_time,
                                    appointment.minutes,
                                ),
                                'p',
                            )}`}</Text>
                            <Text fontWeight="normal" fontSize="sm">
                                {format(
                                    appointment.start_time,
                                    'eeee, LLLL do, yyyy',
                                )}
                            </Text>
                        </Box>
                    }
                />
                {reschedule && (
                    <InfoItem
                        bg="brand.50"
                        p={2}
                        pl={8}
                        label={'Requested Time'}
                        w="calc(100% + 64px)"
                        info={
                            <Box fontWeight="semibold" fontSize="sm" ml={-2}>
                                <Text>{`${format(
                                    reschedule.requested_start_time || 0,
                                    'p',
                                )} to ${format(
                                    addMinutes(
                                        reschedule.requested_start_time || 0,
                                        appointment.minutes,
                                    ),
                                    'p',
                                )}`}</Text>
                                <Text>
                                    {format(
                                        reschedule.requested_start_time || 0,
                                        'LLLL do, yyyy',
                                    )}
                                </Text>
                            </Box>
                        }
                    />
                )}
                {appointment.drop_off_time && (
                    <InfoItem
                        label="Drop Off Time"
                        info={
                            <Box>
                                <Text
                                    fontWeight="normal"
                                    fontSize="sm"
                                >{`${format(
                                    appointment.drop_off_time,
                                    "p 'on' LLLL do, yyyy",
                                )}`}</Text>
                            </Box>
                        }
                    />
                )}
                <InfoItem
                    label="Confirmed At"
                    info={
                        <Box>
                            <Text
                                fontWeight="normal"
                                fontSize="sm"
                                color={
                                    !appointment.confirmed_at
                                        ? 'gray.500'
                                        : 'gray.900'
                                }
                            >
                                {appointment.confirmed_at
                                    ? `${format(
                                          appointment.confirmed_at,
                                          "p 'on' LLLL do, yyyy",
                                      )}`
                                    : 'Not yet confirmed'}
                            </Text>
                        </Box>
                    }
                />
                <InfoItem
                    label="Service"
                    info={
                        <Text
                            fontSize="sm"
                            fontWeight={'normal'}
                            color={!serviceName ? 'gray.500' : 'gray.900'}
                        >
                            {serviceName || 'No service selected'}
                        </Text>
                    }
                />
                <InfoItem
                    label="Clinician"
                    info={
                        <HStack spacing={2}>
                            <ClinicianAvatar
                                clinician={appointment.clinician}
                                size="xs"
                            />
                            <Box>
                                <Text fontSize="sm" fontWeight="normal">
                                    {appointment.clinician?.name}
                                </Text>
                                {appointment.clinician &&
                                    appointment.is_clinician_auto_assigned && (
                                        <SharedHelpText mb={0} mt={'-2px'}>
                                            Auto-assigned
                                        </SharedHelpText>
                                    )}
                            </Box>
                        </HStack>
                    }
                />
                <InfoItem
                    label="Room"
                    info={<InfoText>{appointment.room?.name}</InfoText>}
                />
                <InfoItem
                    label="Client Notes"
                    info={
                        <Text
                            fontSize="sm"
                            color={
                                !appointment.client_notes
                                    ? 'gray.500'
                                    : 'gray.900'
                            }
                        >
                            {appointment.client_notes ? (
                                <em>"{appointment.client_notes}"</em>
                            ) : (
                                'No notes provided'
                            )}
                        </Text>
                    }
                />
                <InfoItem
                    label="Notes in PIMS"
                    info={
                        <Text
                            fontSize="sm"
                            color={
                                !appointment.clinic_notes
                                    ? 'gray.500'
                                    : 'gray.900'
                            }
                        >
                            {appointment.clinic_notes ? (
                                <em>"{appointment.clinic_notes}"</em>
                            ) : (
                                'None'
                            )}
                        </Text>
                    }
                />
                {appointment.referring_clinic_name && (
                    <InfoItem
                        label="Previous Clinic"
                        info={
                            <Text
                                fontSize="sm"
                                color={
                                    appointment.referring_clinic_name ===
                                    'No Previous Clinic'
                                        ? 'gray.500'
                                        : 'gray.900'
                                }
                            >
                                {appointment.referring_clinic_name}
                            </Text>
                        }
                    />
                )}
                <InfoItem
                    label="Messages"
                    info={
                        <Box>
                            <Text color="gray.600" fontSize="sm">
                                {<i>"{chat?.last_message}"</i> ||
                                    'No messages yet'}
                            </Text>
                            <Button
                                as="a"
                                href={
                                    chat?.id
                                        ? UserRoutes.ChatConversation(chat.id)
                                        : UserRoutes.Chats
                                }
                                size="xs"
                            >
                                View Messages
                                <Icon as={FiExternalLink} ml={1} />
                            </Button>
                        </Box>
                    }
                />
                <InfoItem
                    label="Widget Source"
                    info={
                        <Text
                            fontSize="sm"
                            color={
                                !appointment.widget_source
                                    ? 'gray.500'
                                    : 'gray.900'
                            }
                        >
                            {appointment.widget_source || 'None'}
                        </Text>
                    }
                />
                <InfoItem
                    label="Created"
                    info={
                        <Text fontSize="sm">
                            {appointment.pms_created_at
                                ? format(appointment.pms_created_at, 'PPPp')
                                : '-'}
                        </Text>
                    }
                />
                {isAdmin && (
                    <InfoItem
                        label="ID (Admin)"
                        info={
                            <ClickToCopyText
                                fontSize="sm"
                                fontWeight="semibold"
                            >
                                {appointment.id}
                            </ClickToCopyText>
                        }
                    />
                )}
                {isAdmin && reschedule && (
                    <InfoItem
                        label="Reschedule ID (Admin)"
                        info={<Text fontSize="sm">{reschedule.id}</Text>}
                    />
                )}
            </VStack>
        </Box>
    )
}

export default AppointmentInfoSection
