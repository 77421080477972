import React from 'react'
import { Box } from '@chakra-ui/layout'
import { Collapse } from '@chakra-ui/transition'

import {
    ClientFieldsFragment,
    useUpdateClientMutation,
} from '~graphql/generated/graphql'
import SharedLabel from '~components/shared/form/shared-label'
import SharedSwitch from '~components/shared/form/shared-switch'
import SharedStack from '~components/shared/wrappers/shared-stack'
import SharedHelpText from '~components/shared/form/shared-help-text'

interface Props {
    client: ClientFieldsFragment
}

export default function ClientNotificationSettings({ client }: Props) {
    const { settings } = client

    const [updateClient, { loading }] = useUpdateClientMutation()

    async function handleNotificationsEnabled() {
        await updateClient({
            variables: {
                id: client.id,
                data: {
                    settings: {
                        clinic_chckup_notifications_enabled:
                            !settings?.clinic_chckup_notifications_enabled,
                    },
                },
            },
        })
    }

    async function handleSmsEnabled() {
        await updateClient({
            variables: {
                id: client.id,
                data: {
                    settings: {
                        clinic_chckup_sms_enabled:
                            !settings?.clinic_chckup_sms_enabled,
                    },
                },
            },
        })
    }

    async function handleEmailEnabled() {
        await updateClient({
            variables: {
                id: client.id,
                data: {
                    settings: {
                        clinic_chckup_email_enabled:
                            !settings?.clinic_chckup_email_enabled,
                    },
                },
            },
        })
    }

    const areNotificationsEnabled =
        settings?.clinic_chckup_notifications_enabled
    const isSmsEnabled = settings?.clinic_chckup_sms_enabled
    const isEmailEnabled = settings?.clinic_chckup_email_enabled

    return (
        <SharedStack spacing={4}>
            <SharedLabel fontSize="md">Notification Settings</SharedLabel>
            <SharedStack spacing={6}>
                <SwitchItem
                    label="Send Notifications"
                    helpText="Enable or disable notifications for this client."
                    isChecked={areNotificationsEnabled}
                    onChange={handleNotificationsEnabled}
                    isLoading={loading}
                />
                <Collapse in={!!areNotificationsEnabled}>
                    <SharedStack spacing={6} pl={6}>
                        <SwitchItem
                            label="Send SMS"
                            helpText="Enable or disable notifications sent through SMS for this client."
                            isChecked={isSmsEnabled}
                            onChange={handleSmsEnabled}
                            isLoading={loading}
                        />
                        <SwitchItem
                            label="Send Email"
                            helpText="Enable or disable notifications sent through email for this client."
                            isChecked={isEmailEnabled}
                            onChange={handleEmailEnabled}
                            isLoading={loading}
                        />
                    </SharedStack>
                </Collapse>
            </SharedStack>
        </SharedStack>
    )
}

function SwitchItem({
    isChecked,
    helpText,
    label,
    onChange,
    isLoading,
}: {
    isChecked?: boolean
    helpText?: string
    label: string
    onChange: () => void
    isLoading: boolean
}) {
    return (
        <SharedStack spacing={4} row fit>
            <SharedSwitch
                size="sm"
                isDisabled={isChecked === undefined || isLoading}
                isChecked={isChecked}
                onChange={onChange}
            />
            <Box>
                <SharedLabel>{label}</SharedLabel>
                {helpText && <SharedHelpText mb={0}>{helpText}</SharedHelpText>}
            </Box>
        </SharedStack>
    )
}
