import React, { useState } from 'react'
import { Box, Text, Flex } from '@chakra-ui/layout'

import ClientPatientItem from './patient-item'
import DetailedClientHeader from './client-header'
import EmptyState from '~components/shared/empty-state'
import SwitchButton from '~components/shared/switch-button'
import GradientDivider from '~components/shared/gradient-divider'
import { ClientFieldsFragment } from '~graphql/generated/graphql'
import SharedStack from '~components/shared/wrappers/shared-stack'
import { sortPatientsByName } from '~utils/helpers'
import AsyncWrapper from '~components/shared/wrappers/async-wrapper'
import SharedLabel from '~components/shared/form/shared-label'
import ClientNotificationSettings from './notification-settings'

interface Props {
    client: ClientFieldsFragment
}

export default function DetailedClientInfo({ client }: Props) {
    const [showAllPets, setShowAllPets] = useState<boolean>(false)

    const patients = [...(client.patients || [])]
        ?.sort(sortPatientsByName)
        .filter(p => (showAllPets ? true : !p.is_deceased))

    return (
        <>
            <Box>
                <DetailedClientHeader client={client} />
            </Box>
            <GradientDivider my={4} />
            <ClientNotificationSettings client={client} />
            <GradientDivider my={4} />
            <Flex justifyContent="space-between" alignItems="center" mb={3}>
                <SharedLabel fontSize="md">Patients</SharedLabel>
                {patients.length > 0 && (
                    <SwitchButton
                        isChecked={showAllPets}
                        onClick={() => setShowAllPets(!showAllPets)}
                    >
                        Show deceased?
                    </SwitchButton>
                )}
            </Flex>
            <AsyncWrapper
                empty={patients.length === 0}
                emptyComponent={
                    <EmptyState title="No Patients" subtitle=" " py={16} />
                }
            >
                <SharedStack>
                    {patients.map(patient => (
                        <ClientPatientItem key={patient.id} patient={patient} />
                    ))}
                </SharedStack>
            </AsyncWrapper>
        </>
    )
}
